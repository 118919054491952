<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<router-link to="/admin/TemplateCategory/create" class="btn btn-primary">新增</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
									<th width="240px">管理</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.type">
									<td>{{item.type}}</td>
									<td>{{item.label}}</td>

									<td>
										<router-link :to="`/admin/TemplateCategory/edit/${item.editKey}`"
											class="btn btn-outline-success mx-1">編輯</router-link>
										<button @click="handleDelete(item.delKey)"
											className="btn btn-outline-danger mx-1">
											刪除
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" @PageSizeCallback="onPageSizeChange" />

			</div>
		</div>
	</layout-div>
</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listTemplateCategory, deleteTemplateCategory } from '@/api/TemplateCategory.js'


export default {
	name: 'TemplateCategorylist',
	extends: baseList,
	data() {
		return {
			filter: {

			},
			pagination: {
				sortItem: "type",
				sortDir: "ASC",
				page: 1,
				pageSize: 50
			},
			headers: [
				{id: 'type', label: "編號", icon: "▼"},
				{id: 'label', label: "名稱", icon: ""},

			],
			model: [],
			total: 0,
			filterTotal: 0

		};
	},
	methods: {
		reset() {

		},
		getRequest() {
			var result =
			{
				"filter": this.filter,
				"pagination": this.pagination
			};

			return result;
		},
		fetchList() {

			listTemplateCategory(this.getRequest()).then(rep => {

				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc.items;
			});
		},
		deleteRecord(id) {
			return deleteTemplateCategory(id);
		},
		showfilterForm() {
			$("#filterModal").modal('show');
		}
	},
};
</script>
